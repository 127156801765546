import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

// components
import Layout from '../components/Layout';
import HowItWorks from '../components/Pages/HowItWorks';

// hooks
import useTranslated from '../hooks/useTranslated';

const HowItWorksComponent = ({ data, ...rest }) => {
    const { translatedData } = useTranslated(data, rest.pageContext);

    return (
        <Layout
            path={rest.location.pathname}
            lng={rest.pageContext.langKey}
            hasContainer={false}
            showOurCustomers={false}
            metatags={translatedData.strapiHowItWorks.metatags}
            href="/how-it-works/"
        >
            <HowItWorks data={translatedData.strapiHowItWorks} lng={rest.pageContext.langKey} />
        </Layout>
    );
};

HowItWorksComponent.propTypes = {
    data: PropTypes.shape({
        strapiHowItWorks: PropTypes.shape({}).isRequired,
    }).isRequired,
};

export default HowItWorksComponent;

export const query = graphql`
    query HowItWorksDe {
        strapiHowItWorks {
            metatags {
                description_de
                description_en
                title_de
                title_en
            }
            background {
                publicURL
            }
            button {
                label_en
                label_de
                href
            }
            description_en
            description_de
            section {
                description_en
                description_de
                icon {
                    publicURL
                }
                id
            }
            title_en
            title_de
        }
    }
`;
