import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-simple-flex-grid';

import { Background, Image, Section, TextContainer } from './styled';
import Container from '../../global/Container';
import Button from '../../global/ButtonLink';
import { Heading1, Heading3, Paragraph } from '../../global/Typography';

const HowItWorks = ({ data, lng }) => {
    return (
        <>
            <Background src={data.background.publicURL}>
                <Container narrow padding="0 15% 0 20px">
                    <Heading1 white marginBottom="24px">
                        {data.title}
                    </Heading1>
                    <Paragraph white marginBottom="24px">
                        {data.description}
                    </Paragraph>
                    <Button
                        label={data.button.label}
                        href={data.button.href}
                        lng={lng}
                        primary={1}
                    />
                </Container>
            </Background>
            <Container narrow>
                {data.section.map(section => (
                    <Section key={section.id}>
                        <Row gutter={12} justify="stretch">
                            <Col xs={12} sm={4}>
                                <TextContainer>
                                    <Image src={section.icon.publicURL} alt="section image" />
                                </TextContainer>
                            </Col>
                            <Col xs={12} sm={8}>
                                <TextContainer>
                                    <Heading3>{section.description}</Heading3>
                                </TextContainer>
                            </Col>
                        </Row>
                    </Section>
                ))}
            </Container>
        </>
    );
};

HowItWorks.propTypes = {
    data: PropTypes.shape({
        section: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.string.isRequired,
                icon: PropTypes.shape({
                    publicURL: PropTypes.string.isRequired,
                }),
                description: PropTypes.string.isRequired,
            })
        ),
        background: PropTypes.shape({
            publicURL: PropTypes.string.isRequired,
        }),
        button: PropTypes.shape({
            label: PropTypes.string.isRequired,
            href: PropTypes.string.isRequired,
        }),
        title: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
    }).isRequired,
    lng: PropTypes.string.isRequired,
};

export default HowItWorks;
