import { useMemo } from 'react';

// helpers
import { localiseData, getLanguage } from '../utils/helpers';

export default function useTranslated(data, pageContext) {
    const translatedData = useMemo(() => localiseData(data, getLanguage(pageContext)), [
        data,
        pageContext,
    ]);

    return { translatedData };
}
