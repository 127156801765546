import styled from 'styled-components';

export const Background = styled.div`
    background: url(${props => props.src});
    padding: 200px 0;
    background-size: cover;

    margin-bottom: 130px;

    @media all and (max-width: 1366px) {
        padding: 160px 0;
    }

    @media all and (max-width: 1024px) {
        padding: 100px 0;
    }

    @media all and (max-width: 768px) {
        margin-bottom: 28px;
        padding: 60px 0;

        p {
            margin-bottom: 30px;
        }
    }
`;

export const Image = styled.img``;

export const Section = styled.div`
    margin-bottom: 120px;
    @media all and (max-width: 768px) {
        margin-bottom: 28px;
    }
`;

export const TextContainer = styled.div`
    height: 100%;
    display: flex;
    align-items: center;

    @media all and (max-width: 768px) {
        margin-bottom: 20px;
        text-align: center;

        img {
            margin: 0 auto;
            max-width: 70%;
            max-width: 140px;
        }
    }

    @media all and (max-width: 601px) {
        img {
            max-width: 100px;
        }

        h3 {
            font-size: 1rem;
        }
    }
`;
